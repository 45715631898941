<template>
  <v-row>
    <!-- header -->
    <mini-header :dense="true" :breadcrumbs="breadcrumbs" :hiddenTab="true" :addPadding="true">
      <template v-slot:main>
        <v-btn class="ml-2" :to="{name: 'MonitorMapDetail'}" outlined>
          <v-icon left>mdi-map </v-icon>Mapa
        </v-btn>
        <v-menu close-on-click close-on-content-click offset-y left :nudge-bottom="10" transition="slide-y-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="ml-2" color="primary" v-bind="attrs" v-on="on">
              <v-icon left>mdi-folder </v-icon>Grupos<v-icon size="18" right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list class="pa-0">
            <v-list-item class="px-3" v-for="group in groupsList" :key="group.id" :ripple="false">
              <v-list-item-title class="body-2">{{ group.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
     </template>
    </mini-header>
    <!-- end header -->
    <v-col class="pa-0 pb-6">
      <v-card flat>
        <v-toolbar color="transparent" flat height="46">
          <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-semibold"><v-icon size="22" left color="secondary">mdi-chart-timeline-variant</v-icon>Actividad de parque POS</v-toolbar-title>
        </v-toolbar>
        <v-divider />
        <v-card-text class="pa-0">
          <v-row justify="center" no-gutters>
            <v-col cols="4" class="py-5 px-1">
              <v-col class="px-4 py-0">
                <span class="font-weight-bold subtitle-2 secondary--text">Señal de vida</span>
              </v-col>
              <div class="d-flex mt-5">
                <div class="col-4">
                  <v-progress-circular class="progress-circular-pos" rounded :rotate="360" :size="95" :width="10" :value="90" color="primary">
                    <span class="d-block text-h6 secondary--text font-weight-bold">200</span>
                  </v-progress-circular>
                </div>
                <div class="col-7 py-5">
                  <div>
                    <v-avatar class="pb-1 mr-2" size="10" color="primary" />
                    <span :class="`${$vuetify.breakpoint.width > 1200 ? 'mr-4' : 'mr-1'}`" class="ml-1 body-2">Activos (150)</span>
                  </div>
                  <div class="mt-2">
                    <v-avatar class="pb-1 mr-2" size="10" color="var(--light-purple)" />
                    <span class="ml-1 body-2">Inactivos (50)</span>
                  </div>
                  <router-link :to="{name: 'PosListCreate', query: { ordering: '-created,-created'}}" class="d-block mt-5 text-decoration-none primary--text">Ir a POS</router-link>
                </div>
              </div>
              </v-col>
            <v-divider vertical />
            <v-col cols="4" class="py-5 px-4">
              <span class="font-weight-bold subtitle-2 secondary--text mx-auto">Tiendas</span>
              <div class="text-center">
                <span class="d-block fontBody--text text-h2 font-weight-semibold">100</span>
                <span class="d-block secondary--text subtitle-2 mt-1">Total de tiendas</span>
                <router-link :to="{name: 'BranchListCreate', query: { ordering: '-created,-created'}}" class="d-block mt-5 text-decoration-none primary--text">Ver tiendas</router-link>
              </div>
            </v-col>
            <v-divider vertical />
            <v-col cols="4" class="text-center py-10 px-0">
              <div >
                <v-progress-circular class="progress-circular-pos-error" rounded :rotate="360" :size="95" :width="10" :value="90" color="error">
                  <span class="d-block text-h6 secondary--text font-weight-bold">35%</span>
                </v-progress-circular>
                <span class="d-block align-center secondary--text subtitle-2 mt-2">Riesgo operacional<v-icon right color="secondary" size="16">mdi-information-outline</v-icon></span>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-row>
        <v-col>
          <v-card class="mt-5" flat>
            <v-toolbar color="transparent" flat height="46">
              <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-semibold"><v-icon size="22" left color="secondary">mdi-store</v-icon>Tiendas por estado</v-toolbar-title>
            </v-toolbar>
            <v-divider />
            <v-card-text class="pa-0">
              <v-col class="py-2 px-1">
                <v-simple-table class="fixed-table mb-n6">
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td>
                          <v-chip color="redLight" label>Critico <v-icon class="pt-1" right>mdi-alert</v-icon></v-chip>
                        </td>
                        <td style="width: 20%; text-align: end">
                          <span class="text-subtitle-2">
                            <span class="font-weight-semibold">50</span> tiendas
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <v-chip color="yellowLight" label>Medio <v-icon class="pt-1" right>mdi-minus-circle</v-icon></v-chip>
                        </td>
                        <td style="width: 20%; text-align: end">
                          <span class="text-subtitle-2">
                            <span class="font-weight-semibold">25</span> tiendas
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <v-chip color="greenExtraLight" label>Normal <v-icon class="pt-1" right>mdi-check-circle</v-icon></v-chip>
                        </td>
                        <td style="width: 20%; text-align: end">
                          <span class="text-subtitle-2">
                            <span class="font-weight-semibold">25</span> tiendas
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
              <v-divider vertical />
            </v-card-text>
            <v-divider />
            <v-card-actions class="px-5 py-4 cursor-pointer" @click="$router.push({name: 'BranchReportList'})">
              <v-spacer />
              <router-link :to="{name: 'BranchReportList'}" class="d-block text-body-1 secondary--text text-decoration-none">Ir al reporte completo</router-link>
              <v-spacer />
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col>
          <v-card class="mt-5" flat>
            <v-toolbar color="transparent" flat height="46">
              <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-semibold"><v-icon size="22" left color="secondary">mdi-printer-pos</v-icon>POS por estado</v-toolbar-title>
            </v-toolbar>
            <v-divider />
            <v-card-text class="pa-0">
              <v-col class="py-2 px-1">
                <v-simple-table class="fixed-table mb-n6">
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td>
                          <v-chip color="redLight" label>Critico <v-icon class="pt-1" right>mdi-alert</v-icon></v-chip>
                        </td>
                        <td style="width: 20%; text-align: end">
                          <span class="text-subtitle-2">
                            <span class="font-weight-semibold">50</span> POS
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <v-chip color="yellowLight" label>Medio <v-icon class="pt-1" right>mdi-minus-circle</v-icon></v-chip>
                        </td>
                        <td style="width: 20%; text-align: end">
                          <span class="text-subtitle-2">
                            <span class="font-weight-semibold">80</span> POS
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <v-chip color="greenExtraLight" label>Normal <v-icon class="pt-1" right>mdi-check-circle</v-icon></v-chip>
                        </td>
                        <td style="width: 20%; text-align: end">
                          <span class="text-subtitle-2">
                            <span class="font-weight-semibold">25</span> POS
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
              <v-divider vertical />
            </v-card-text>
            <v-divider />
            <v-card-actions class="px-5 py-4" @click="$router.push({name: 'PosBranchReportList'})">
              <v-spacer />
              <router-link :to="{name: 'PosReportList'}" class="d-block text-body-1 secondary--text text-decoration-none">Ir al reporte completo</router-link>
              <v-spacer />
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <v-card class="mt-5" flat>
        <v-toolbar color="transparent" flat height="46">
          <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-semibold"><v-icon size="22" left color="secondary">mdi-chart-timeline-variant</v-icon>Monitor de folio</v-toolbar-title>
        </v-toolbar>
        <v-divider />
        <v-card-text class="pa-0">
          <v-row justify="center" no-gutters>
            <v-col class="text-center py-10 px-0">
              <div>
                <v-progress-circular class="progress-circular-pos-error" rounded :rotate="360" :size="95" :width="10" :value="90" color="error">
                  <span class="d-block text-h6 secondary--text font-weight-bold">76%</span>
                </v-progress-circular>
                <span class="d-block align-center secondary--text subtitle-2 mt-2">Riesgo operacional<v-icon right color="secondary" size="16">mdi-information-outline</v-icon></span>
              </div>
            </v-col>
            <v-divider vertical />
            <v-col class="py-5 px-4">
              <div class="mt-5 text-center">
                <span class="d-block fontBody--text text-h2 font-weight-semibold">4.653.740</span>
                <span class="d-block secondary--text subtitle-2 mt-1">Folios disponibles</span>
                <router-link :to="{name: 'FoliosReportList'}" class="d-block mt-5 text-decoration-none primary--text">Ver stock de folios</router-link>
              </div>
            </v-col>
             <!--<v-divider vertical />
            <v-col cols="4" class="text-center py-10 px-0">
              <div>
                <v-progress-circular class="progress-circular-pos-error" rounded :rotate="360" :size="95" :width="10" :value="90" color="error">
                  <span class="d-block text-h6 secondary--text font-weight-bold">76%</span>
                </v-progress-circular>
                <span class="d-block align-center secondary--text subtitle-2 mt-2">Riesgo operacional<v-icon right color="secondary" size="16">mdi-information-outline</v-icon></span>
              </div>
            </v-col> -->
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import groups from '@/data/groupsList' // example
import VEmptyState from '@/components/commons/VEmptyState'
import MiniHeader from '@/components/commons/MiniHeader'

export default {
  components: {
    VEmptyState,
    MiniHeader
  },
  data: () => ({
    groupsList: groups,
    breadcrumbs: {
     main: 'Monitor',
     children: []
   }
  })
}
</script>